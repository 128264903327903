import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormikProvider, useFormik } from "formik";
import CurrencyInput from 'react-currency-input-field';
import * as Yup from 'yup';

import {
  Box,
  Container,
  FormControl,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Button,
  Grid,
  Typography,
  Tooltip,
  IconButton
} from "@mui/material";

import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

import { ContratoTab } from "../../contratoTab";
import { ServicoTab } from "../../servicoTab";
import { BoxBase, ButtonBase, CheckboxBase, CustomTabPanel, FormControlBase, FormSectionBox, FormSectionTitle, InputLabelSelect, SelectBase, tabProps, TextFieldBase } from "../../../styles";
import { PedidosContext } from "../../../../../Context/PedidosContext";
import { ModalLiberacao } from "../../modalLiberacao";
import Mensagem from "../../../../../Components/Mensagem";
import api from "../../../../../Api/apiAxios";
import { MyContext } from "../../../../../Context/MyContext";
import Loading from "../../../../../Components/Loading";
import { format, subDays } from "date-fns";
import { Edit } from "@mui/icons-material";
import { InscricoesAdicionais } from "../../inscricoesAdicionais";

export function PedidoFolha({ isShow = false}) {
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [showMensagem, setShowMensagem] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');

  const navigate = useNavigate();

  const { id } = useParams();

  const { setMensagemGlobal, setTipoMensagemGlobal } = useContext(MyContext);
  const { 
    distribuidores, 
    modalLiberacao, 
    setModalLiberacao, 
    setDadosTabs, 
    motivosCancelamento, 
    pedidoLiberado, 
    setPedidoLiberado,
    parseNumberCurrency, 
  } = useContext(PedidosContext);

  const nivelUsuario = window.localStorage.getItem('nivelUsuario');  

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  async function handleDistribuidorChange(e) {
    formik.handleChange(e);
    formik.setFieldValue('cliente', 0);
    formik.setFieldValue('nomeFantasia', '');
    formik.setFieldValue('numeroSerie', 0);
  }

  async function handleClienteChange(e) {
    formik.handleChange(e);
    formik.setFieldValue('nomeFantasia', '');
    formik.setFieldValue('numeroSerie', 0);

    const idCliente = e.target.value;

    const response = await api.get(`/Cliente/${idCliente}`);
    formik.setFieldValue('nomeFantasia', response.data.nomeFantasia);

    if (formik.values.tipoPedido === 1) {
      const res = await api.get(`/LicencaFolha/CLIENTE/${response.data.id}`);
      
      if (res.data.numeroSerie) {
        loadFields(res.data, false)
      }
    }
  };

  const handleSave = async (values) => {
    setMensagemGlobal('')
    setTipoMensagemGlobal('')
    setShowMensagem(false)
     
    let regPedido = {
      Id: id || 0,
      ModuloFolha: formik.values.calculoFolha ? 1 : 0,       
      ModuloSST: formik.values.moduloSST ? 1 : 0,       
      ModuloPAJ: formik.values.moduloPAJ ? 1 : 0,       
      ModuloCompetenciaTreinamento: formik.values.competenciaTreinamento ? 1 : 0,
      ModuloCargosSalarios: formik.values.cargosSalarios ? 1 : 0,
      ModuloEpi: formik.values.moduloEPI ? 1 : 0,
      ModuloRpa: formik.values.moduloRPA ? 1 : 0,
      ModuloExamesMedicos: formik.values.examesMedicos ? 1 : 0,
      ModuloPpp: formik.values.moduloPPP ? 1 : 0,
      ControleEmpresas: formik.values.controleEmpresas ? 1 : 0,
      DistribuidorId: formik.values.distribuidor,    
      TipoPedido: formik.values.tipoPedido,
      ClienteId: formik.values.cliente,    
      NumeroSerie: Number(formik.values.numeroSerie),
      StatusPedido: formik.values.statusPedido,
      TipoBancoDados: formik.values.bancoDeDados,
      TipoInstalacao: formik.values.tipoInstalacao,
      VersaoSistema: formik.values.versao.toString().includes('.')
        ? formik.values.versao
        : formik.values.versao.toString().substring(0, 2) + 
          (formik.values.versao.toString().substring(2).length > 0 
            ? '.' + formik.values.versao.toString().substring(2) 
            : ''),
      VersaoRelease: formik.values.release ? formik.values.release.toString() : '',
      DataPedido: formik.values.dataPedido,
      Observacao: formik.values.observacoes,
      MotivoCancelamento: '',
      NumeroOrdemCompra: '',
      ValorBruto: parseNumberCurrency(formik.values.totalPedido),
      ValorDesconto: 0,
      ValorLiquido: parseNumberCurrency(formik.values.totalPedido),
      ValorMensal: parseNumberCurrency(formik.values.valorMensal),
      IntegracaoAcesso: 0,
      IntegracaoFolha: 0,
      IntegracaoPonto: formik.values.ponto ? 1 : 0,
      Contrato: formik.values.contrato ? 1 : 0,
      FaixaFuncionarios: formik.values.faixaFuncionarios || 0,            
      QuantidadeUsuarios: formik.values.quantidadeUsuarios || 0,
      QuantidadeParcelas: 1,
      DataCancelamento: formik.values.dataCancelamento,
      motivoCancelamentoOpcao: formik.values.motivoCancelamentoOpcao,
      motivoCancelamento: formik.values.motivoCancelamento,
    }              
  
    try {
      let ret;

      if (id) {
        ret = await api.put("BmaFolha/", JSON.stringify(regPedido), {  headers: { 'Content-Type': 'application/json'} });
      } else {
        ret = await api.post("BmaFolha/", JSON.stringify(regPedido), { headers: { 'Content-Type': 'application/json'} });
      }

      if (!id && ret.data.id > 0) {
        setMensagem('Pedido incluído com sucesso!');
        setTipoMensagem("success");
        setShowMensagem(true);
        navigate(`/viewpedidofolha/${ret.data.id}`)
      } else if (id) {
        setMensagem('Pedido alterado com sucesso!');
        setTipoMensagem("success");
        setShowMensagem(true);
        navigate(`/viewpedidofolha/${ret.data.id}`)
      }

    } catch (error) {
      console.error(error);
      setMensagem(error);
      setTipoMensagem("danger");
      setShowMensagem(true);
    } 
  }

  const loadFields = (values, allFields) => {
    if (allFields) {
      formik.setFieldValue('tipoPedido', values.tipoPedido)
      formik.setFieldValue('distribuidor', values.distribuidorId)
      formik.setFieldValue('cliente', values.clienteId)
      formik.setFieldValue('nomeFantasia', values.cliente.nomeFantasia)
      formik.setFieldValue('observacoes', values.observacao)
      formik.setFieldValue('totalMensal', values.valorMensal)
      formik.setFieldValue('totalPedido', values.valorBruto)
      formik.setFieldValue('quantidadeParcelas', values.quantidadeParcelas)
      formik.setFieldValue('statusPedido', values.statusPedido)
      formik.setFieldValue('versao', values.versaoSistema)
      formik.setFieldValue('release', values.versaoRelease)
      formik.setFieldValue('dataPedido', format(new Date(values.dataPedido), 'yyyy-MM-dd'))
      formik.setFieldValue('razaoSocial', values.cliente.razaoSocial)
      formik.setFieldValue('numeroInscricao', values.cliente.numeroInscricao)
    }

    formik.setFieldValue('numeroSerie', values.numeroSerie)
    formik.setFieldValue('bancoDeDados', values.tipoBancoDados)
    formik.setFieldValue('tipoInstalacao', values.tipoInstalacao)
    formik.setFieldValue('faixaFuncionarios', values.faixaFuncionarios)
    formik.setFieldValue('quantidadeUsuarios', values.quantidadeUsuarios)
    formik.setFieldValue('contrato', values.temContrato === 1)
    formik.setFieldValue('ponto', values.wpe === 1)
    formik.setFieldValue('controleEmpresas', values.controleEmpresas === 1)
    formik.setFieldValue('calculoFolha', values.moduloFolha === 1)
    formik.setFieldValue('examesMedicos', values.moduloExamesMedicos === 1)
    formik.setFieldValue('moduloSST', values.moduloSST === 1)
    formik.setFieldValue('moduloPAJ', values.moduloPAJ === 1)
    formik.setFieldValue('moduloEPI', values.moduloEpi === 1)
    formik.setFieldValue('moduloRPA', values.moduloRpa === 1)
    formik.setFieldValue('moduloPPP', values.moduloPpp === 1)
    formik.setFieldValue('competenciaTreinamento', values.moduloCompetenciaTreinamento === 1)
    formik.setFieldValue('cargosSalarios', values.moduloCargosSalarios === 1)
    if (values.dataCancelamento !== null) formik.setFieldValue('dataCancelamento', format(new Date(values.dataCancelamento), 'yyyy-MM-dd'))
    formik.setFieldValue('motivoCancelamentoOpcao', values.motivoCancelamentoOpcao)
    formik.setFieldValue('motivoCancelamento', values.motivoCancelamento)
  }

  const validationSchema = Yup.object({
    tipoPedido: Yup.number().nullable().required('O tipo de pedido é obrigatório'),
    distribuidor: Yup.number().nullable().required('A revenda é obrigatório'),
    cliente: Yup.number().nullable().required('O cliente é obrigatório'),
    nomeFantasia: Yup.string()
      .nullable(),
    dataPedido: Yup.date()
    .required('Data de Pedido é obrigatória'),
    observacoes: Yup.string().nullable(),
    statusPedido: Yup.number().nullable(),
    numeroSerie: Yup.string()
      .nullable()
      .matches(/^[0-9]+$/, 'Número de série deve conter apenas números'),
    bancoDeDados: Yup.string().nullable().required('O banco de dados é obrigatório'),
    tipoInstalacao: Yup.number().nullable().required('O tipo de instalação é obrigatório'),
    quantidadeUsuarios: Yup.number()
      .nullable(),
    faixaFuncionarios: Yup.number()
      .nullable(),
    controleEmpresas: Yup.boolean().required(),
    cargosSalarios: Yup.boolean().required(),
    moduloEPI: Yup.boolean().required(),
    calculoFolha: Yup.boolean().required(),
    moduloSST: Yup.boolean().required(),
    moduloPAJ: Yup.boolean().required(),
    competenciaTreinamento: Yup.boolean().required(),
    examesMedicos: Yup.boolean().required(),
    ponto: Yup.boolean().required(),
    moduloRPA: Yup.boolean().required(),
    moduloPPP: Yup.boolean().required(),
    contrato: Yup.boolean().required(),
    totalPedido: Yup.string()
      .nullable(),
    valorMensal: Yup.string()
      .nullable(),
    versao: Yup.string()
    .nullable(),
    release: Yup.string()
      .nullable(),
    dataCancelamento: Yup.date()
      .nullable().min(subDays(new Date(), 1), 'A data de cancelamento deve ser a partir de hoje.'),
    motivoCancelamentoOpcao: Yup.number()
      .nullable(),
    motivoCancelamento: Yup.string()
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      tipoPedido: null,
      distribuidor: null,
      cliente: null,
      nomeFantasia: '',
      dataPedido: new Date(),
      observacoes: '',
      statusPedido: 0,
      numeroSerie: 0,
      bancoDeDados: null,
      tipoInstalacao: null,
      quantidadeUsuarios: 0,
      faixaFuncionarios: 0,
      controleEmpresas: false,
      cargosSalarios: false,
      moduloEPI: false,
      calculoFolha: false,
      moduloSST: false,
      moduloPAJ: false,
      competenciaTreinamento: false,
      examesMedicos: false,
      ponto: false,
      moduloRPA: false,
      moduloPPP: false,
      contrato: true,
      totalPedido: "0,00",
      valorMensal: "0,00",
      versao: '',
      release: '',
      dataCancelamento: null,
      motivoCancelamentoOpcao: 0,
      motivoCancelamento: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
  })

  useEffect(() => {
    const getClientes = async (id) => {
      if (!id) return;
  
      try {
        const { data } = await api.get(`/Cliente/${id}/A`);
        
        const registrosFiltrados = data
          .filter((cliente) => cliente.statusEmpresa === 1)
          .sort((a, b) => a.razaoSocial.localeCompare(b.razaoSocial));
  
        setClientes(registrosFiltrados);
      } catch (error) {
        console.error("Erro ao buscar clientes:", error);
      }
    };
  
    getClientes(formik.values.distribuidor);
  }, [formik.values.distribuidor]);

  useEffect(() => {
    async function setVersaoAtual() {
      const response = await api.get(`/Versao/retornar-versao-atual?tipoSistema=3`);

      formik.setFieldValue('versao', response.data.numeroVersao)
      formik.setFieldValue('release', response.data.release)
    }

    setVersaoAtual(); 

    if (formik.values.statusPedido === 0) {
      setPedidoLiberado(false);
    }
  }, [])

  useEffect(() => {
    const loadData = async () => {
     if (!id) return;
 
     try {
       setIsLoading(true)
       const response = await api.get(`/BmaFolha/${id}`);
 
       loadFields(response.data, true)
     } catch (e) {
       console.log(e)
     } finally {
       setIsLoading(false)
     }
    }
 
    loadData();
   }, [id])

  useEffect(() => {
    if (formik.isSubmitting && formik.errors) {
      console.log(formik.errors)
      setMensagem('Preencha os campos obrigatórios.')
      setTipoMensagem('danger');
      setShowMensagem(true)
    }
  }, [formik.errors])

  useEffect(() => {
    setDadosTabs({ 
     distribuidor: formik.values.distribuidor, 
     cliente: formik.values.cliente, 
     nomeFantasia: formik.values.nomeFantasia, 
     numeroSerie: formik.values.numeroSerie })
 }, [
   formik.values.distribuidor,
   formik.values.cliente,
   formik.values.nomeFantasia,
   formik.values.numeroSerie
 ])

  if (isLoading) return <Loading />

  return (
    <Box>
      {showMensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem}/>}

      {modalLiberacao.show && 
        <ModalLiberacao 
          pedido={modalLiberacao.pedido} 
        />
      }
      <Container sx={{ py: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Tabs
            sx={{ backgroundColor: 'ghostwhite', boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.05)' }}
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Pedido" {...tabProps(0)} />
            <Tab label="Contrato" {...tabProps(1)} disabled={!formik.values.contrato || !id} />
            <Tab label="Serviço" {...tabProps(2)} disabled={!id}/>
          </Tabs>
          <Typography component='h1' variant='h5' sx={{ margin: '0.6rem 13%', fontWeight: 400, color: '#a3a3a3' }}>Pedido - Folha</Typography>
        </Box>
        <Box>
          <Paper sx={{ backgroundColor: '#F7F8F8', width: '70rem'}} elevation={3}>
            <CustomTabPanel value={value} index={0}>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', mb: 2, mt: -1 }}>
                {isShow && formik.values.statusPedido === 0 &&
                  <Button
                    variant='text'
                    startIcon={<DriveFolderUploadIcon />}
                    onClick={() => setModalLiberacao({ show: true, pedido: { id: id, cliente: { razaoSocial: formik.values.razaoSocial, numeroInscricao: formik.values.numeroInscricao }, contrato: formik.values.contrato, tipoInstalacao: formik.values.tipoInstalacao }} )} 
                    color='success'
                    disabled={nivelUsuario !== '1' || pedidoLiberado}
                  >
                    Liberar Pedido
                  </Button>
                }
                {isShow && formik.values.statusPedido === 0 && (nivelUsuario === '1' || nivelUsuario === '3') &&
                  <Tooltip title='Editar'>
                    <IconButton onClick={() => navigate(`/pedidofolha/${id}`)}>
                      <Edit fontSize="small" />
                    </IconButton>
                  </Tooltip>
                }
              </Box>
              <Box 
                component='form'
                onSubmit={formik.handleSubmit}
                sx={{display: 'flex', flexDirection: 'column', gap: 1}}
              >
                <FormSectionBox>
                  <FormSectionTitle label="Dados do Pedido" />
                  <Container sx={{display: 'flex', flexDirection: 'column', my: 3, justifyContent: 'center'}}>
                    <BoxBase>
                      <FormControlBase>
                        <InputLabelSelect id="tipoPedido">Tipo de Pedido</InputLabelSelect>
                        <SelectBase 
                          id="tipoPedido"
                          name="tipoPedido"
                          label='Tipo de Pedido'
                          value={formik.values.tipoPedido}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.tipoPedido && Boolean(formik.errors.tipoPedido)}
                          helperText={formik.touched.name && formik.errors.name}
                          isShow={isShow}
                          width='20rem'
                        >
                          <MenuItem value={0}>Cópia Nova</MenuItem>
                          <MenuItem value={1}>Atualização</MenuItem>
                          <MenuItem value={2}>Simulação</MenuItem>
                        </SelectBase>
                      </FormControlBase>

                      <FormControlBase>
                        <InputLabelSelect id="distribuidor">Revenda</InputLabelSelect>
                        <SelectBase 
                          id="distribuidor" 
                          label="Revenda"
                          name="distribuidor"
                          value={formik.values.distribuidor}
                          onChange={(e) => handleDistribuidorChange(e)}
                          onBlur={formik.handleBlur}
                          error={formik.touched.distribuidor && Boolean(formik.errors.distribuidor)}
                          helperText={formik.touched.distribuidor && formik.errors.distribuidor}
                          isShow={isShow}
                          width='20rem'
                        >
                          {distribuidores.map((distribuidor) => (
                            <MenuItem
                              key={distribuidor.id}
                              value={distribuidor.id}
                            >
                              {distribuidor.razaoSocial}
                            </MenuItem>
                          ))}
                        </SelectBase>
                      </FormControlBase>
                      <FormControlBase>
                        <InputLabelSelect id="cliente">Cliente</InputLabelSelect>
                        <SelectBase 
                          id="cliente" 
                          label="Cliente"
                          name="cliente"
                          value={formik.values.cliente}
                          onChange={(e) => handleClienteChange(e)}
                          onBlur={formik.handleBlur}
                          error={formik.touched.cliente && Boolean(formik.errors.cliente)}
                          helperText={formik.touched.cliente && formik.errors.cliente}
                          isShow={isShow}
                          width='20rem'
                        >
                          {clientes.map((cliente) => (
                            <MenuItem
                              key={cliente.id}
                              value={cliente.id}
                            >
                              {cliente.razaoSocial} - {cliente.numeroInscricao}
                            </MenuItem>
                          ))}
                        </SelectBase>
                      </FormControlBase>
                    </BoxBase>
                    
                    <BoxBase> 
                      <FormControlBase>
                        <TextFieldBase 
                          label="Nome Fantasia"
                          value={formik.values.nomeFantasia}
                          width='30.5rem'
                          disabled
                        />
                      </FormControlBase>

                      <FormControlBase>
                        <TextFieldBase 
                          label="Número Série"
                          name="numeroSerie"
                          value={formik.values.numeroSerie}
                          onChange={formik.handleChange}
                          error={formik.touched.numeroSerie && Boolean(formik.errors.numeroSerie)}
                          helperText={formik.touched.numeroSerie && formik.errors.numeroSerie}
                          width='30.5rem'
                          textAlign='center'
                          disabled={nivelUsuario !== '1' || isShow}  
                        />
                      </FormControlBase>
                    </BoxBase>

                      <FormControlBase>
                        <TextFieldBase 
                          label="Observações"
                          name="observacoes"
                          value={formik.values.observacoes}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.observacoes && Boolean(formik.errors.observacoes)}
                          helperText={formik.touched.observacoes && formik.errors.observacoes}
                          width='100%'  
                          multiline 
                          rows={4}
                          disabled={isShow}
                        />
                      </FormControlBase>

                      {(formik.values.statusPedido === 2 || formik.values.dataCancelamento !== null) &&
                      <>
                        <br />
                        <BoxBase>
                          <FormControlBase>
                            <TextFieldBase 
                              label="Data do Cancelamento" 
                              name="dataCancelamento"
                              value={formik.values.dataCancelamento}
                              type="date"
                              onChange={formik.handleChange}
                              onBlur={formik.onBlur}
                              error={formik.touched.dataCancelamento && Boolean(formik.errors.dataCancelamento)}
                              helperText={formik.touched.dataCancelamento && formik.errors.dataCancelamento}
                              width='20rem'
                              disabled={isShow}
                            />
                          </FormControlBase>

                          <FormControlBase>
                            <InputLabelSelect id="motivoCancelamentoOpcao">Motivo do Cancelamento</InputLabelSelect>
                            <SelectBase 
                              id='motivoCancelamentoOpcao'
                              name='motivoCancelamentoOpcao'
                              disabled={isShow}
                              onChange={formik.handleChange}
                              onBlur={formik.onBlur}
                              error={formik.touched.motivoCancelamentoOpcao && Boolean(formik.errors.motivoCancelamentoOpcao)}
                              helperText={formik.touched.motivoCancelamentoOpcao && formik.errors.motivoCancelamentoOpcao}
                              label='Motivo do Cancelamento'
                              value={formik.values.motivoCancelamentoOpcao}
                              width='41rem'
                              margin='0 0 0 -5px'
                            >
                              {motivosCancelamento.map((motivo) => (
                                <MenuItem key={motivo.id} value={motivo.id}>
                                  {motivo.descricaoMotivo}
                                </MenuItem>
                              ))}
                            </SelectBase>
                          </FormControlBase>
                        </BoxBase>

                        <br />

                        <FormControlBase>
                          <TextFieldBase 
                            label="Detalhes do Cancelamento"
                            name="motivoCancelamento"
                            onChange={formik.handleChange}
                              onBlur={formik.onBlur}
                              error={formik.touched.motivoCancelamento && Boolean(formik.errors.motivoCancelamento)}
                              helperText={formik.touched.motivoCancelamento && formik.errors.motivoCancelamento}
                            value={formik.values.motivoCancelamento}
                            disabled={isShow}
                            width='100%'  
                            multiline 
                            rows={4}
                          />
                        </FormControlBase>
                      </>
                      }
                  </Container>
                </FormSectionBox>

                <FormSectionBox>
                  <FormSectionTitle label="Dados da Licença" />
                  <Container sx={{display: 'flex', flexDirection: 'column', my: 3}}>
                    <BoxBase>
                      <FormControlBase>
                        <InputLabelSelect  id="tipoInstalacao">Tipo de Instalação</InputLabelSelect>
                        <SelectBase 
                          id="tipoInstalacao" 
                          label="Tipo de Instalação"
                          name="tipoInstalacao"
                          value={formik.values.tipoInstalacao}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.tipoInstalacao && Boolean(formik.errors.tipoInstalacao)}
                          helperText={formik.touched.tipoInstalacao && formik.errors.tipoInstalacao}
                          width='30.5rem'
                          isShow={isShow}
                        >
                            <MenuItem value={0}>Licenciado</MenuItem>
                            <MenuItem value={1}>SaaS</MenuItem>
                        </SelectBase>
                      </FormControlBase>

                      <FormControlBase>
                        <InputLabelSelect id="bancoDeDados">Banco de Dados</InputLabelSelect>
                        <SelectBase 
                          id="bancoDeDados" 
                          label="Banco de Dados"
                          name="bancoDeDados"
                          value={formik.values.bancoDeDados}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.bancoDeDados && Boolean(formik.errors.bancoDeDados)}
                          helperText={formik.touched.bancoDeDados && formik.errors.bancoDeDados}
                          isShow={isShow}
                          width='30.5rem'
                        >
                          <MenuItem value={1}>MySql</MenuItem>
                          <MenuItem value={2}>SqlServer</MenuItem>
                          <MenuItem value={3}>Oracle</MenuItem>
                        </SelectBase>
                      </FormControlBase>
                    </BoxBase>

                    <BoxBase>
                        <FormControlBase>
                          <TextFieldBase 
                            id="quantidadeUsuarios"
                            name="quantidadeUsuarios"
                            label="Quantidade de Usuários"
                            value={formik.values.quantidadeUsuarios}
                            onChange={formik.handleChange}
                            disabled={isShow}
                            width='30.5rem'
                            type='number' 
                            textAlign='center'
                          />
                        </FormControlBase>

                        <FormControlBase>
                          <TextFieldBase 
                            id="faixaFuncionarios"
                            name="faixaFuncionarios"
                            label="Faixa de Funcionários"
                            disabled={isShow}
                            onChange={formik.handleChange}
                            value={formik.values.faixaFuncionarios}
                            width='30.5rem'
                            type='number' 
                            textAlign='center'
                          />
                        </FormControlBase>
                      </BoxBase>

                      <FormikProvider value={formik}>
                        <Grid container spacing={2} sx={{ pb: 2, pl: 4, mt: 1, display: 'flex' }}>
                          <Grid item xs={3}>
                            <CheckboxBase
                              name="controleEmpresas"
                              checked={formik.values.controleEmpresas}
                              onChange={formik.handleChange}
                              disabled={isShow}
                              label="Controle Empresas"
                            />                        
                          </Grid>
                          <Grid item xs={3}>
                            <CheckboxBase
                              name="cargosSalarios"
                              checked={formik.values.cargosSalarios}
                              onChange={formik.handleChange}
                              disabled={isShow}
                              label="Cargos e Salários"
                            />  
                          </Grid>
                          <Grid item xs={3}>
                            <CheckboxBase
                              name="moduloEPI"
                              checked={formik.values.moduloEPI}
                              onChange={formik.handleChange}
                              disabled={isShow}
                              label="Módulo EPI"
                            /> 
                          </Grid>
                          <Grid item xs={3}>
                            <CheckboxBase
                              name="calculoFolha"
                              checked={formik.values.calculoFolha}
                              onChange={formik.handleChange}
                              disabled={isShow}
                              label="Cálculo Folha"
                            /> 
                          </Grid>
                          <Grid item xs={3}>
                            <CheckboxBase
                              name="moduloSST"
                              checked={formik.values.moduloSST}
                              onChange={formik.handleChange}
                              disabled={isShow}
                              label="SST"
                            /> 
                          </Grid>
                          <Grid item xs={3}>
                            <CheckboxBase
                              name="moduloPAJ"
                              checked={formik.values.moduloPAJ}
                              onChange={formik.handleChange}
                              disabled={isShow}
                              label="PAJ"
                            /> 
                          </Grid>
                          <Grid item xs={3}>
                            <CheckboxBase
                              name="competenciaTreinamento"
                              checked={formik.values.competenciaTreinamento}
                              onChange={formik.handleChange}
                              disabled={isShow}
                              label="Competência/Treinamento"
                            /> 
                          </Grid>
                          <Grid item xs={3}>
                            <CheckboxBase
                              name="examesMedicos"
                              checked={formik.values.examesMedicos}
                              onChange={formik.handleChange}
                              disabled={isShow}
                              label="Exames Médicos"
                            /> 
                          </Grid>
                          <Grid item xs={3}>
                            <CheckboxBase
                              name="ponto"
                              checked={formik.values.ponto}
                              onChange={formik.handleChange}
                              disabled={isShow}
                              label="Ponto"
                            /> 
                          </Grid>
                          <Grid item xs={3}>
                            <CheckboxBase
                              name="moduloRPA"
                              checked={formik.values.moduloRPA}
                              onChange={formik.handleChange}
                              disabled={isShow}
                              label="RPA"
                            /> 
                          </Grid>
                          <Grid item xs={3}>
                            <CheckboxBase
                              name="moduloPPP"
                              checked={formik.values.moduloPPP}
                              onChange={formik.handleChange}
                              disabled={isShow}
                              label="PPP"
                            /> 
                          </Grid>
                          <Grid item xs={3}>
                            <CheckboxBase
                              name="contrato"
                              checked={formik.values.contrato}
                              onChange={formik.handleChange}
                              disabled={isShow}
                              label="Contrato"
                            /> 
                          </Grid>
                        </Grid>
                    </FormikProvider>
                  </Container>
                </FormSectionBox>

                <FormSectionBox>
                  <FormSectionTitle label="Totais" />
                  <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Grid container spacing={2} sx={{ pt: 2, pb: 4, px: 2 }}>
                      <Grid item xs={6}>
                      <FormControl>
                        <CurrencyInput
                          customInput={TextFieldBase}
                          label="Total Pedido"
                          name="totalPedido"
                          value={formik.values.totalPedido}
                          onValueChange={(value) => formik.setFieldValue('totalPedido', value)}
                          onBlur={formik.handleBlur}
                          intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                          disabled={nivelUsuario !== '1' || isShow}
                          textAlign="center"
                          decimalScale={2}
                          decimalsLimit={2}
                          width="30.5rem"
                          mb={1.5}
                        />
                      </FormControl>

                      </Grid>
                      <Grid item xs={6}>
                        <FormControl>
                          <CurrencyInput
                            customInput={TextFieldBase}
                            label="Valor Mensal"
                            name="valorMensal"
                            value={formik.values.valorMensal}
                            onValueChange={(value) => formik.setFieldValue('valorMensal', value)}
                            onBlur={formik.handleBlur}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            disabled={nivelUsuario !== '1' || isShow}
                            textAlign="center"
                            decimalScale={2}
                            decimalsLimit={2}
                            width="30.5rem"
                            mb={1.5}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </FormSectionBox>

                { id && formik.values.statusPedido === 0 && 
                  <InscricoesAdicionais clienteId={formik.values.cliente} pedidoId={id} />
                }

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                  {isShow || formik.values.tipoPedido === 2 ? (
                    <ButtonBase variant="outlined" onClick={() => navigate('/pedidos')}>
                      Voltar
                    </ButtonBase>
                  ) : (
                    <>
                      <ButtonBase variant="outlined" onClick={() => navigate('/pedidos')}>
                        Cancelar
                      </ButtonBase>
                      <ButtonBase type="submit" variant="contained" disabled={nivelUsuario === "2" || nivelUsuario ==="4"}>
                        Salvar
                      </ButtonBase>	
                    </>
                  )}
                </Box>
              </Box>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <ContratoTab isShow={isShow} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <ServicoTab isShow={isShow || nivelUsuario !== "1"} />
            </CustomTabPanel>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
}
