import React, { useContext, useEffect, useState } from "react";
import { Container, Grid, Button, Switch, MenuItem, Table, TableBody, TableContainer, TableHead, TableRow, Paper, Tooltip, IconButton } from "@mui/material";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';

import { FormControlBase, InputLabelSelect, SelectBase, TextFieldBase } from "../../Pedidos/styles";
import { BaseActionButton } from "../../Pedidos/components/actionButtons";
import { StyledTableCell } from '../../Pedidos/components/acesso/acessoIndex'

import api from "../../../Api/apiAxios";
import { USUARIOS_VALIDAR_EMAIL } from "../../../Api/apiFetch";
import { MyContext } from "../../../Context/MyContext";

export function AdicionarUsuario({ distribuidorId, statusRevenda }) {
  const [usuarios, setUsuarios] = useState([]);
  const [novoUsuario, setNovoUsuario] = useState(null);
  const [editandoUsuario, setEditandoUsuario] = useState(null);

  const nivelUsuario = window.localStorage.getItem('nivelUsuario');

  const { setMensagemGlobal, setTipoMensagemGlobal } = useContext(MyContext);

  const tipoUsuarioLabel = {
    1: "Admin - BMA",
    2: "Consulta - BMA",
    3: "Admin - Revenda",
    4: "Consulta - Revenda",
  };

  const handleAddUsuario = () => {
    setNovoUsuario({
      distribuidorId: distribuidorId || '',
      nome: '',
      email: '',
      celular: '',
      senha: 'portal123',
      tipoUsuario: null,
      status: 1,
    });
  };

  const handleEditUsuario = (usuario) => {
    setEditandoUsuario(usuario);
    setNovoUsuario({
      ...usuario,
      distribuidorId: distribuidorId || '',
      tipoUsuario: usuario.tipoUsuario,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNovoUsuario({
      ...novoUsuario,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      const email = novoUsuario.email
      const { url, options } = USUARIOS_VALIDAR_EMAIL({ email });        
      const response = await fetch(url, options);

      var retorno = await(response.json());

      if  (retorno.id > 0 && (!editandoUsuario || retorno.id !== Number(novoUsuario.id))) {
          setMensagemGlobal("E-mail já cadastrado, favor informar outro e-mail !");
          setTipoMensagemGlobal("danger");
          return;
      }

      let senha;

      if (editandoUsuario) {
        const { data } = await api.get(`/Usuario/${editandoUsuario.id}`);
        senha = data.senha;
      }

      const regUsuario = {
        Id: editandoUsuario?.id || 0,
        DistribuidorId: Number(distribuidorId),
        Nome: novoUsuario.nome,
        Email: novoUsuario.email,
        Senha: editandoUsuario ? senha : novoUsuario.senha,
        Telefone: novoUsuario.celular,
        Celular: novoUsuario.celular,
        Status: novoUsuario.status,
        TipoUsuario: Number(novoUsuario.tipoUsuario),
        DataHoraCadastro: editandoUsuario ? new Date(editandoUsuario.dataHoraCadastro) : new Date(),
        DataHoraUltimoAcesso: new Date(),
      };

      if (editandoUsuario) {
        await api.put("Usuario/", JSON.stringify(regUsuario), {
          headers: { "Content-Type": "application/json" },
          withCredentials: false
        });
      } else {
        await api.post("Usuario/", JSON.stringify(regUsuario), {
          headers: { "Content-Type": "application/json" },
        });
      }

      setNovoUsuario(null);
      setEditandoUsuario(null);
      loadUsuarios();
    } catch (error) {
      console.error("Erro ao salvar usuário:", error);
    }
  };

  async function loadUsuarios() {
    try {
      const resp = await api.get(`/Usuario/retornar-por-distribuidor?idDistribuidor=${distribuidorId}`);
      setUsuarios(resp.data);
    } catch (error) {
      console.error("Erro ao carregar usuários:", error);
    }
  }

  useEffect(() => {
    loadUsuarios();
  }, [distribuidorId]);

  return (
    <Container sx={{ my: 2 }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Nome</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Celular</StyledTableCell>
              <StyledTableCell>Tipo</StyledTableCell>
              <StyledTableCell>Ativo</StyledTableCell>
              <StyledTableCell>Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usuarios.map((usuario, index) => (
              <TableRow key={index}>
                <StyledTableCell>{usuario.nome}</StyledTableCell>
                <StyledTableCell>{usuario.email}</StyledTableCell>
                <StyledTableCell>{usuario.celular}</StyledTableCell>
                <StyledTableCell>{tipoUsuarioLabel[usuario.tipoUsuario]}</StyledTableCell>
                <StyledTableCell>
                  <Switch checked={usuario.status === 1} disabled />
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip title='Editar'>
                    <BaseActionButton color='#ffc107' onClick={() => handleEditUsuario(usuario)} disabled={nivelUsuario === '2' || nivelUsuario === '4' || novoUsuario !== null}>
                      <EditIcon />
                    </BaseActionButton>
                  </Tooltip>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      {(statusRevenda && (nivelUsuario === '1' || nivelUsuario === '3')) &&
        <Button 
          onClick={handleAddUsuario} 
          variant="text" 
          sx={{ display: 'flex', alignItems: 'center' }} 
          disabled={novoUsuario !== null}
        >
          <AddCircleIcon sx={{ marginRight: '0.5rem' }} />
          Novo Contato
        </Button>
      }
      {novoUsuario && (
        <Container sx={{ my: 3 }}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <TextFieldBase
                label="Nome"
                name="nome"
                value={novoUsuario.nome}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item md={3}>
              <TextFieldBase
                label="Email"
                name="email"
                type="email"
                value={novoUsuario.email}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item md={3}>
              <TextFieldBase
                label="Celular"
                name="celular"
                value={novoUsuario.celular}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item md={3}>
              <TextFieldBase
                label="Senha"
                name="senha"
                type={novoUsuario.senha === 'portal123' ? 'text' : 'password'}
                value={novoUsuario.senha}
                onChange={handleChange}
                disabled={editandoUsuario}
                fullWidth
              />
            </Grid>
            <Grid item md={3}>
              <FormControlBase>
                <InputLabelSelect>Tipo de Usuário</InputLabelSelect>
                <SelectBase
                  name="tipoUsuario"
                  value={novoUsuario.tipoUsuario || ''}
                  onChange={(e) => setNovoUsuario({...novoUsuario, tipoUsuario: parseInt(e.target.value, 10)})}
                  fullWidth
                >
                  {nivelUsuario === '1' && <MenuItem value={1}>Admin - BMA</MenuItem>}
                  {nivelUsuario === '1' && <MenuItem value={2}>Consulta - BMA</MenuItem>}
                  <MenuItem value={3}>Admin - Revenda</MenuItem>
                  <MenuItem value={4}>Consulta - Revenda</MenuItem>
                </SelectBase>
              </FormControlBase>
            </Grid>
            <Grid item md={3}>
              <Switch
                checked={novoUsuario.status === 1}
                onChange={(e) => setNovoUsuario({ ...novoUsuario, status: e.target.checked ? 1 : 0 })}
              />
              Ativo
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Confirmar
              </Button>
              <Button variant="outlined" color="secondary" onClick={() => { setNovoUsuario(null); setEditandoUsuario(null); }} sx={{ ml: 2 }}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}
    </Container>
  );
}
